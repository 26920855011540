/*
 * @LastEditTime: 2022-08-05 15:07:52
 * @Description: please input some description
 * @FilePath: /dataview-next/src/global/actions/ShowRelationDetail.js
 * @Date: 2022-04-28 16:24:21
 * @Author: lisushuang
 * @LastEditors: lisushuang
 */
/* eslint-disable */

import { Col, Input, Message, Row } from "element-ui";
import Vue from "vue";
import objects from "../../api/objects";
import baseAction from "./baseAction";
import { Button } from 'element-ui';
import RelationsForm from "../../views/objects/components/relationsForm.vue"



export default class ShowRelationDetail extends baseAction {

  relation = new Vue({ data: { detail: null } });

  constructor() {
    super()
  }


  handler() {
    console.log('ShowRelationDetail', this.props)
    let relation_uuid = null;

    if (this.props) {
      if (this.props.uuid !== undefined) relation_uuid = this.props.uuid
      else if (this.props.relation_uuid !== undefined) relation_uuid = this.props.relation_uuid
      else relation_uuid = this.props
    }

    if(!relation_uuid){
      this.showError('展示关联关系详情：非法参数！')
      return
    }

    this.getLoading()
    objects.getRelationDetail(relation_uuid).then(res => {
      this.relation.detail = res.data.data
      this.showPopDialog(
        this.showRelationSetting, 
        '【关联关系】：' + this.relation.detail.name + ' 【code】：' + this.relation.detail.code,
        this.footer
      )
      this.loading.close()
    }).catch(() => {
      this.loading.close()
    })
  }

  /**
   * @description: 展示字段信息以及配置
   */
  showRelationSetting = () => {
    let h = this.mountInstance.$createElement
    return (
      <div>
        <RelationsForm 
          formData={this.relation.detail} 
          obuuid={this.relation.detail.owner_uuid} 
          ref="relationsForm"
          onAddFn={(data) => this.saveRelationData(data)}
        />
      </div>
    )
  }

  footer = () => {
    let h = this.mountInstance.$createElement
    return (
      <Button type="success" onClick={() => this.saveRelationData()} > 保存关联关系配置 </Button>
    )
  }

  saveRelationData(data = null) {
    if(data === null ){
      this.mountInstance.$refs.relationsForm.commit()
    }else{
      this.getLoading()
      objects.editRelation(this.relation.detail.uuid, data).then(res => {
        if(res.data.code == 200){
          this.relation.detail.name = data.name
          this.loading.close()
          Message.success("关联关系【"+data.name+"】修改成功！")
        }
      }).catch(()=>{Message.error("修改失败，请稍后再试");this.loading.close()})
    }
  }
}
